import React, { Component } from 'react';

class AboutUs extends Component {
  render() {
    return (
      <div>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>About Us Section</title>
        <link
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
              .image-section {
                  position: relative;
                  background: url('https://balajitechs.com/wp-content/uploads/2024/02/people-with-laptops-office-scaled.jpg') no-repeat center center;
                  background-size: cover;
                  height: 400px;
              }
              .overlay {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  background: rgba(0, 0, 0, 0.5);
              }
              .text-box {
                  position: absolute;
                  top: 50%;
                  left: 10%;
                  transform: translateY(-50%);
                  color: white;
              }
              .highlight {
                  background-color: #d4af37;
                  padding: 0 10px;
                  display: inline-block;
              }
              @media (max-width: 768px) {
                  .text-box {
                      left: 5%;
                      text-align: center;
                      width: 90%;
                  }
                  .image-section {
                      height: 250px;
                  }
                  .highlight {
                      font-size: 36px;
                  }
              }
            `
          }}
        />
        <div className="image-section">
          <div className="overlay" />
          <div className="text-box">
            <h1 className="highlight fw-bold" style={{ backgroundColor: '#c1b460', fontSize: '50px' }}>
              About Us
            </h1>
            <h5>Discover Our Story: Crafting Comfort and Quality with Every Solution</h5>
          </div>
        </div>

        {/* Our Promise Section */}
        <section className="our-promise py-5 bg-light text-center">
          <div className="container">
            <h1 className="text-center mb-5 fw-bold" style={{ color: '#15284C', fontSize: '50px' }}>
              Our Promise
            </h1>
            <p>
              At Balaji Technologies, we are passionate about leveraging cutting-edge technologies to empower businesses in achieving their digital goals. With a dedicated team of experts, we specialize in a wide range of services including web development, web design, and CRM management solutions.
            </p>
            <img
              src="https://balajitechs.com/wp-content/uploads/2024/02/360_F_619769049_pxEEvXT1UjRmsgV7UNGw3RFE1FNMjrOq.jpg"
              className="img-fluid mt-4"
              alt="Our Promise"
            />
          </div>
        </section>

        {/* Our Differentiators Section */}
        <section className="our-differentiators py-5 text-center">
          <div className="container">
            <h2 className="text-center mb-5 fw-bold" style={{ color: '#15284C', fontSize: '50px' }}>
              Our Differentiators
            </h2>
            <div className="row">
              <div className="col-md-6 mb-4">
                <h3>Our Approach</h3>
                <p>
                  We believe in delivering tailor-made solutions that perfectly align with our clients' unique needs and objectives. By combining creativity with technical expertise, we craft stunning websites that not only captivate audiences but also drive results.
                </p>
                <img
                  src="https://balajitechs.com/wp-content/uploads/2024/04/top-view-abstract-innovation-assortment-1024x576.jpg"
                  className="img-fluid mt-4"
                  alt="Our Approach"
                />
              </div>
              <div className="col-md-6 mb-4">
                <h3>Our Expertise</h3>
                <p>
                  We believe in delivering tailor-made solutions that perfectly align with our clients' unique needs and objectives. By combining creativity with technical expertise, we craft stunning websites that not only captivate audiences but also drive results.
                </p>
                <img
                  src="https://balajitechs.com/wp-content/uploads/2024/04/standard-quality-control-collage-concept-1024x683.jpg"
                  className="img-fluid mt-4"
                  style={{ height: '303px' }}
                  alt="Our Expertise"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Technologies Section */}
        <section className="technologies py-5 bg-light text-center">
          <div className="container">
            <h1 className="text-center mb-5 fw-bold" style={{ color: '#15284C', fontSize: '50px' }}>
              Some of the
              <span style={{ color: '#C1B460' }}> technologies we work </span>
              <br />
              with include
            </h1>
            <div className="row">
              {/* Add a column for each technology logo */}
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-1-qmvq5egrsue53wozqynvarlp9fzowvv1y1fdg7m680.png"
                  className="img-fluid"
                  alt="WordPress"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>WordPress</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-2-qmvq7sxx9lois17dq01jo5s3ww5gl1ecxxg1lo1ybk.png"
                  className="img-fluid"
                  alt="Shopify"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>Shopify</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com_-qmvq8hdq7alz5w7vralugzm3cwt0563dpaeo2v1pts.png"
                  className="img-fluid"
                  alt="ClickFunnels"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>ClickFunnels</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/581c3b61fa7537728171377614eec639_icon-qmvqpqp1os8ic55dz968r4ts0lqmh3lyepmmarghkw.jpg"
                  className="img-fluid"
                  alt="GoHighLevel"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>GoHighLevel</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/kartra-icon-filled-256-1-qmvtofdldua42u6l0dbur7n78zwe4d572r8l7d9vcw.png"
                  className="img-fluid"
                  alt="Kartra"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>Kartra</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-4-qmvtyk2z1k5d7jgtuqz3mnm1qhyt2xd1swczeq94ao.png"
                  className="img-fluid"
                  alt="Wix"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>Wix</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/images-1-qmvtqgp06x2h8f83490r5p55l1v0sr89cu4gmy95vk.png"
                  className="img-fluid"
                  alt="Kajabi"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>Kajabi</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-3-qmvtrnxkxapo2hha3rplce8cwrxwmtzwus4spoh1xc.png"
                  className="img-fluid"
                  alt="Unbounce"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>Unbounce</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/download-4-qmvtxlay0otr9yvchtxwkedzr8q864izc480nhooow.png"
                  className="img-fluid"
                  alt="GrooveFunnels"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>GrooveFunnels</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-5-qmvu0ikva4tveamff3g4bntmaebc484x2laeehclc0.png"
                  className="img-fluid"
                  alt="Squarespace"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>Squarespace</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-7-qmvu7m8yx2jv48b1y5yn5vazv7b69wbwpqqgxqtibk.png"
                  className="img-fluid"
                  alt="HubSpot"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>HubSpot</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-8-qmvua6d5iu1yq0l909s2y8262yp588hlqeo1yv0xds.png"
                  className="img-fluid"
                  alt="Mailchimp"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>Mailchimp</h3>
                </a>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-4">
                <img
                  src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-6-qmvu5y3aro9ih6qfpfyiqahhujjol9p35gze81apds.png"
                  className="img-fluid"
                  alt="ActiveCampaign"
                />
                <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" style={{ color: '#15284c' }}>
                  <h3>ActiveCampaign</h3>
                </a>
              </div>
              
              {/* Repeat similar blocks for other technologies */}
            </div>
          </div>
        </section>

        {/* Our Commitment Section */}
        <section className="our-commitment py-5 text-center">
          <div className="container">
            <h1 className="text-center mb-5 fw-bold" style={{ color: '#15284c',fontSize: '50px' }}>
              Our Commitment
            </h1>
            <p>
              At Balaji Technologies, client satisfaction is our top priority. We are committed to delivering exceptional results, providing ongoing support, and ensuring that every project exceeds expectations.
            </p>
            <p>
              Partner with us to transform your digital presence and take your business to new heights. Let's embark on this journey together.
            </p>
            <p>
              Welcome to Balaji Technologies – Where Innovation Meets Excellence.
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default AboutUs;
