import React from 'react';

const Portfolio = () => {
  return (
    <>
     <meta charSet="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Portfolio</title>
  <link
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        .image-section {\n            position: relative;\n            background: url('https://balajitechs.com/wp-content/uploads/2024/03/istockphoto-1413884806-612x612-1.jpg') no-repeat center center;\n            background-size: cover;\n            height: 400px;\n        }\n        .overlay {\n            position: absolute;\n            top: 0;\n            bottom: 0;\n            left: 0;\n            right: 0;\n            background: rgba(0, 0, 0, 0.5);\n        }\n        .text-box {\n            position: absolute;\n            top: 50%;\n            left: 10%;\n            transform: translateY(-50%);\n            color: white;\n        }\n        .highlight {\n            background-color: #d4af37;\n            padding: 0 10px;\n            display: inline-block;\n        }\n    "
    }}
  />
  <div className="image-section">
    <div className="overlay" />
    <div className="text-box">
      <h1 className="highlight fw-bold"style={{backgroundColor:'#c1b460',fontSize: '50px'}}>Portofolio</h1>
      <h5>Discover Our Portfolio: Showcasing Our Work and Expertise</h5>
    </div>
  </div>
  <meta charSet="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n    .custom-divider {\n      border: 0;\n      height: 5px;\n      background-color: #C1B460;\n      margin: 20px auto; /* Center the divider */\n      width: 50%;\n    }\n  "
    }}
  />
  <title>Portfolio</title>
  <div className="container text-center mt-3">
    <p className="lead">
      Welcome to our portfolio page, where we showcase some of the projects
      we’ve had the pleasure of working on. At Balaji Technologies, we take
      pride in delivering exceptional results for our clients, and we’re excited
      to share our success stories with you.
    </p>
    
    <h1 className="fw-bold mt-7"style={{ color: "#15284C",fontSize: '45px' }}>View Our Work</h1>
    <p className="mt-4"style={{fontSize: '19px'}}>
      Explore our portfolio below to see examples of our work and discover how
      we’ve helped our clients achieve their digital goals. If you’re interested
      in learning more about any of the projects or discussing how we can help
      you with your next project, don’t hesitate to get in touch.
    </p>
    <p className="mt-4"style={{fontSize: '19px'}}>
      At Balaji Technologies, we’re committed to delivering excellence in
      everything we do. Let’s work together to bring your vision to life and
      make your project a success.
    </p>
  </div>
    <div className="container my-5">
      <div className="row">
        {/* Portfolio Item 1 */}
        <div className="col-md-4 mb-4">
          <div className="card">
            <img src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-1-qmvq5egrsue53wozqynvarlp9fzowvv1y1fdg7m680.png" className="card-img-top" alt="Web Development" style={{ width: '82px', height: '82px', margin: '18px 20px 0 20px' }} />
            <div className="card-body">
              <h5 className="card-title"style={{ color: "#15284C" }}>WordPress</h5>
              <p className="card-text">A brief description of WordPress.</p>
              <a href="project1-link" className="btn btn-primary">View Project</a>
            </div>
          </div>
        </div>
        {/* Portfolio Item 2 */}
        <div className="col-md-4 mb-4">
          <div className="card">
          <img src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-2-qmvq7sxx9lois17dq01jo5s3ww5gl1ecxxg1lo1ybk.png" className="card-img-top" alt="Web Development" style={{ width: '82px', height: '82px', margin: '18px 20px 0 20px' }} />
            <div className="card-body">
              <h5 className="card-title"style={{ color: "#15284C" }}>Shopify</h5>
              <p className="card-text">A brief description of Shopify.</p>
              <a href="project2-link" className="btn btn-primary">View Project</a>
            </div>
          </div>
        </div>
        {/* Portfolio Item 3 */}
        <div className="col-md-4 mb-4">
          <div className="card">
            <img src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com_-qmvq8hdq7alz5w7vralugzm3cwt0563dpaeo2v1pts.png" className="card-img-top" alt="Web Development" style={{ width: '82px', height: '82px', margin: '18px 20px 0 20px' }} />
            <div className="card-body">
              <h5 className="card-title"style={{ color: "#15284C" }}>ClickFunnels</h5>
              <p className="card-text">A brief description of ClickFunnels.</p>
              <a href="project3-link" className="btn btn-primary">View Project</a>
            </div>
          </div>
        </div>
        {/* Portfolio Item 4 */}
        <div className="col-md-4 mb-4">
          <div className="card">
            <img src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/581c3b61fa7537728171377614eec639_icon-qmvqpqp1os8ic55dz968r4ts0lqmh3lyepmmarghkw.jpg" className="card-img-top" alt="Web Development" style={{ width: '82px', height: '82px', margin: '18px 20px 0 20px' }} />
            <div className="card-body">
              <h5 className="card-title"style={{ color: "#15284C" }}>GoHighLevel</h5>
              <p className="card-text">A brief description of GoHighLevel.</p>
              <a href="project4-link" className="btn btn-primary">View Project</a>
            </div>
          </div>
        </div>
        {/* Portfolio Item 5 */}
        <div className="col-md-4 mb-4">
          <div className="card">
            <img src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-4-qmvtyk2z1k5d7jgtuqz3mnm1qhyt2xd1swczeq94ao.png" className="card-img-top" alt="Web Development" style={{ width: '82px', height: '82px', margin: '18px 20px 0 20px' }} />
            <div className="card-body">
              <h5 className="card-title"style={{ color: "#15284C" }}>Wix</h5>
              <p className="card-text">A brief description of Wix.</p>
              <a href="project5-link" className="btn btn-primary">View Project</a>
            </div>
          </div>
        </div>
        {/* Portfolio Item 6 */}
        <div className="col-md-4 mb-4">
          <div className="card">
            <img src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-5-qmvu0ikva4tveamff3g4bntmaebc484x2laeehclc0.png" className="card-img-top" alt="Web Development" style={{ width: '82px', height: '82px', margin: '18px 20px 0 20px' }} />
            <div className="card-body">
              <h5 className="card-title"style={{ color: "#15284C" }}>Squarespace</h5>
              <p className="card-text">A brief description of Squarespace.</p>
              <a href="project6-link" className="btn btn-primary">View Project</a>
            </div>
          </div>
        </div>
         {/* Portfolio Item 7 */}
         <div className="col-md-4 mb-4">
          <div className="card">
            <img src="https://balajitechs.com/wp-content/uploads/elementor/thumbs/pngwing.com-3-qmvtrnxkxapo2hha3rplce8cwrxwmtzwus4spoh1xc.png" className="card-img-top" alt="Web Development" style={{ width: '82px', height: '82px', margin: '18px 20px 0 20px' }} />
            <div className="card-body">
              <h5 className="card-title"style={{ color: "#15284C" }}>Unbounce</h5>
              <p className="card-text">A brief description of Unbounce.</p>
              <a href="project7-link" className="btn btn-primary">View Project</a>
            </div>
          </div>
        </div>
         {/* Portfolio Item 8 */}
         <div className="col-md-4 mb-4">
          <div className="card">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0bNFRNPDWOMqO7zuWEUNlktjE0eU-5q-UlQ&s" className="card-img-top" alt="Web Development" style={{ width: '82px', height: '82px', margin: '18px 20px 0 20px' }} />
            <div className="card-body">
              <h5 className="card-title"style={{ color: "#15284C" }}>Web Flow</h5>
              <p className="card-text">A brief description of Web Flow.</p>
              <a href="project2-link" className="btn btn-primary">View Project</a>
            </div>
          </div>
        </div>
        
        {/* Add more portfolio items as needed */}
      </div>
    </div>
    </>
  );
}

export default Portfolio;
