import React, { Component } from 'react';

class CallSchedule extends Component {
  render() {
    return (
      <>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>About Us Section</title>
        <link
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
              .image-section {
                position: relative;
                background: url('https://balajitechs.com/wp-content/uploads/2024/03/schedule-organization-planning-list-concept_53876-147954.png') no-repeat center center;
                background-size: cover;
                height: 400px;
              }
              .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.5);
              }
              .text-box {
                position: absolute;
                top: 50%;
                left: 10%;
                transform: translateY(-50%);
                color: white;
              }
              .highlight {
                background-color: #d4af37;
                padding: 0 10px;
                display: inline-block;
              }
              .animated-image {
                animation: pulse 3s infinite;
              }
              @keyframes pulse {
                0% {
                  transform: scale(1);
                }
                50% {
                  transform: scale(1.05);
                }
                100% {
                  transform: scale(1);
                }
              }
            `,
          }}
        />
        <div className="image-section">
          <div className="overlay" />
          <div className="text-box">
            <h1
              className="highlight fw-bold"
              style={{ backgroundColor: '#c1b460', fontSize: '50px' }}
            >
              Call Schedule
            </h1>
            <h5>Connect with Us: Let's Start a Conversation!</h5>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-8 mx-auto text-center">
              <h1
                className="display-4 fw-bold"
                style={{ color: '#c1b460',fontSize: '40px'}}
              >
                Connect with Us: 
              </h1>
              <h4 className="display-4" style={{ fontSize: '25px' }}>
                Connect with Us: Let's Start a Conversation!
              </h4>
              <p className="lead mt-4">
                Ready to discuss your project or have questions about our
                services? Schedule a call with us today! Our team of experts is
                here to provide personalized assistance and guidance tailored to
                your specific needs.
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-5 mb-6">
          <div
            className="card w-100"
            style={{
              borderRadius: '20px',
              boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, 0.5)',
            }}
          >
            <div className="row no-gutters">
              <div className="col-md-6 d-flex align-items-center">
                <div
                  className="card-body"
                  style={{ padding: '20px 0px 20px 25px' }}
                >
                  <h2
                    className="card-title fw-bold"
                    style={{ color: '#15284C', paddingBottom: '20px' }}
                  >
                    Why Schedule a Call?
                  </h2>
                  <ul>
                    <li className="mb-2">
                      Get personalized advice and recommendations from our
                      experienced team.
                    </li>
                    <li className="mb-2">
                      Discuss your project requirements and objectives in
                      detail.
                    </li>
                    <li className="mb-2">
                      Receive answers to any questions you may have about our
                      services.
                    </li>
                    <li className="mb-2">
                      Explore how Balaji Technologies can help you achieve your
                      digital goals.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="https://balajitechs.com/wp-content/uploads/2024/02/telephone-612061_1280.jpg"
                  alt="Schedule Call"
                  className="img-fluid w-90 h-90 animated-image"
                  style={{
                    objectFit: 'cover',
                    padding: '23px 24px 29px 24px',
                    borderRadius: '44px',
                  }}
                />
              </div>
            </div>
          </div>
          <h2
            className="card-title fw-bold text-center mt-4"
            style={{ color: '#15284C' }}
          >
            Schedule Your Call
          </h2>
          <p className="text-center mt-4">
            Use the calendar below to select a date and time for your call. We
            look forward to speaking with you and helping you take your project
            to the next
            <br />
            level!
          </p>
        </div>
      </>
    );
  }
}

export default CallSchedule;
