import React, { Component } from 'react';

class Home extends Component {
    render() {
          
          
        return (
          
            <>
      <meta charSet="UTF-8" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<title>Background Video Section</title>
<link
  rel="stylesheet"
  href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
/>
<style
  dangerouslySetInnerHTML={{
    __html:
      `
    .video-background {
      position: relative;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }

    .video-background video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    .video-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: left;
      color: white;
      border-radius: 10px;
      max-width: 90%;
      width: 100%;
    }

    .video-content h1 {
      font-size: 3rem;
      font-weight: bold;
    }

    .video-content p {
      font-size: 1.2rem;
      line-height: 1.5;
    }

    .video-content .btn {
      margin-top: 20px;
      font-size: 1rem;
    }

    /* Responsive Adjustments */
    @media (max-width: 768px) {
      .video-content h1 {
        font-size: 2rem;
      }

      .video-content p {
        font-size: 1rem;
        line-height: 1.4;
      }

      .video-content .btn {
        font-size: 0.9rem;
      }
    }

    @media (max-width: 576px) {
      .video-content h1 {
        font-size: 34px;
      }

      .video-content p {
        font-size: 0.9rem;
      }

      .video-content .btn {
        font-size: 0.8rem;
      }
    }
  `,
  }}
/>
<section
  className="video-background"
  style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
  <video autoPlay muted loop>
    <source
      src="https://balajitechs.com/wp-content/uploads/2024/02/video-1080p.mp4"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
  <div className="video-content">
    <h3 className="text-wheat font-syne font-weight-normal text-capitalize text-left">
      Welcome To Balaji Technologies
    </h3>
    <h1
      className="fw-bold mb-3 mt-2"
      style={{ color: '#fff', textAlign: 'left' }}
    >
      Building the Future<br />
      with Technology
    </h1>
    <p
      className="text-wheat font-manrop font-weight-500 "
      style={{ textAlign: 'left' }}
    >
      Balaji Technologies is a custom software development company that
      provides IT staff<br /> augmentation services including design,
      project management, web development, CRM<br /> Management, Graphics
      Design, and other services.
    </p>
    <a
      href="/contact"
      className="btn btn-primary btn-lg mt-3 mb-5"
      style={{
        backgroundColor: '#C1B460',
        bordercolor: '#C1B460',
        borderColor: '#C1B460',
        color: '#FFFFFF',
        borderRadius: '0px',
        fontFamily: '"Manrope", Sans-serif',
        letterSpacing: '0.3px',
      }}
    >
      Let's Get Started
    </a>
  </div>
</section>

<div className="container mt-5 mb-7">
  <div className="row">
    <div className="col-md-6">
      <div className="left-content">
        <div>
          <h1
            className="font-mont fw-bold"
            style={{
              fontSize: '39px',
              color: '#15284C',
            }}
          >
            OUR DREAM IS GLOBAL LEARNING TRANSFORMATION
          </h1>
        </div>
        <div
          className="text-black font-manrope font-weight-500"
          style={{
            fontSize: '16px',
          }}
        >
          <p>
            We engineer the technologies that make the web better for everyone. We make publishing easier with modern editorial tools, improve ownership through cleverly personalized workflows, and boost conversions with lightning-fast user experiences.
          </p>
          <p>
            We’re a team of high-achieving web design and digital solution professionals supporting Indian organisations to provide meaningful customer experiences and scale up their businesses online.
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <img
        src="https://balajitechs.com/wp-content/uploads/2024/02/student-849825_1280-1024x682.jpg"
        alt="Image Description"
        className="img-fluid"
        style={{ borderRadius: '30px', opacity: 0.9 }}
      />
        </div>
      </div>
     </div> 
        
           
          <div style={{ backgroundColor: '#f6f6f6',padding: '20px 0px 20px 0px'}}>
        <div className="container my-5 custom-container" style={{ maxWidth: '1145px' }}>
       <h2 className="text-center mb-4 fw-bold" style={{ color: "#15284C", fontSize: '40px' }}>
       Why Choose Us
      <div className="custom-divider" style={{ border: 0, height: '4px', backgroundColor: '#C1B460', width: '9%', margin: '20px auto' }} />
      </h2>
  <p className="text-center" style={{ color: "#15284C" }}>
    You will get highly intuitive, attractive, and scalable mobile apps that enhance user experience.
  </p>
  <div className="row align-items-center mt-5">
    <div className="col-md-4 text-md-end ">
      <ul className="list-unstyled">
        <li className="mb-4 d-flex align-items-center justify-content-md-end justify-content-end"style={{textAlign: 'end'}} >
          <div>
            <h5 style={{ color: "#C1B460" }}>CONFIDENTIALITY</h5>
            <p style={{ color: "#15284C" }}>Sign NDA for every project</p>
          </div>
          <img src="https://balajitechs.com/wp-content/uploads/2024/02/lock.webp" alt="Confidentiality" className="ms-3" style={{ width: '40px', height: '40px' }} />
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-md-end justify-content-end"style={{textAlign: 'end'}} >
          <div>
            <h5 style={{ color: "#C1B460" }}>COMPETITIVE PRICING</h5>
            <p style={{ color: "#15284C" }}>Best rates in the industry</p>
          </div>
          <img src="https://balajitechs.com/wp-content/uploads/2024/02/doller.webp" alt="Competitive Pricing" className="ms-3" style={{ width: '40px', height: '40px' }} />
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-md-end justify-content-end"style={{textAlign: 'end'}} >
          <div>
            <h5 style={{ color: "#C1B460" }}>AGILE METHODOLOGY</h5>
            <p style={{ color: "#15284C" }}>Manage project well & scale anytime</p>
          </div>
          <img src="https://balajitechs.com/wp-content/uploads/2024/02/agil-model.webp" alt="Agile Methodology" className="ms-3" style={{ width: '40px', height: '40px' }} />
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-md-end justify-content-end"style={{textAlign: 'end'}} >
          <div>
            <h5 style={{ color: "#C1B460" }}>5+ YEARS</h5>
            <p style={{ color: "#15284C" }}>Experience in the company</p>
          </div>
          <img src="https://balajitechs.com/wp-content/uploads/2024/04/five-stars_9137804.png" alt="5+ Years" className="ms-3" style={{ width: '40px', height: '40px' }} />
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-md-end justify-content-end"style={{textAlign: 'end'}} >
          <div>
            <h5 style={{ color: "#C1B460" }}>CODING STANDARD</h5>
            <p style={{ color: "#15284C" }}>Experience in the company</p>
          </div>
          <img src="https://balajitechs.com/wp-content/uploads/2024/02/coding-standred.webp" alt="Coding Standard" className="ms-3" style={{ width: '40px', height: '40px' }} />
        </li>
      </ul>
    </div>
    <div className="col-md-4 text-center my-4 my-md-0">
      <img src="https://balajitechs.com/wp-content/uploads/2024/02/pexels-julia-m-cameron-4145356-683x1024.jpg" className="img-fluid" alt="Why Choose Us" />
    </div>
    <div className="col-md-4 text-md-start ">
      <ul className="list-unstyled">
        <li className="mb-4 d-flex align-items-center justify-content-md-start justify-content-space-around">
          <img src="https://balajitechs.com/wp-content/uploads/2024/02/ip-rights.webp" alt="IP Rights" className="me-3" style={{ width: '40px', height: '40px' }} />
          <div>
            <h5 style={{ color: "#C1B460" }}>IP RIGHTS</h5>
            <p style={{ color: "#15284C" }}>Completely owned by the client</p>
          </div>
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-md-start justify-content-space-around">
          <img src="https://balajitechs.com/wp-content/uploads/2024/02/project-delivery.webp" alt="Project Delivery" className="me-3" style={{ width: '40px', height: '40px' }} />
          <div>
            <h5 style={{ color: "#C1B460" }}>PROJECT DELIVERY</h5>
            <p style={{ color: "#15284C" }}>On-time project delivery</p>
          </div>
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-md-start justify-content-space-around">
          <img src="https://balajitechs.com/wp-content/uploads/2024/02/trusted.webp" alt="Globally Trusted" className="me-3" style={{ width: '40px', height: '40px' }} />
          <div>
            <h5 style={{ color: "#C1B460" }}>GLOBALLY TRUSTED</h5>
            <p style={{ color: "#15284C" }}>5-star rated company</p>
          </div>
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-md-start justify-content-space-around">
          <img src="https://balajitechs.com/wp-content/uploads/2024/02/engagment-mode.webp" alt="Engagement Models" className="me-3" style={{ width: '40px', height: '40px' }} />
          <div>
            <h5 style={{ color: "#C1B460" }}>ENGAGEMENT MODELS</h5>
            <p style={{ color: "#15284C" }}>Flexible as per your needs</p>
          </div>
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-md-start justify-content-space-around">
          <img src="https://balajitechs.com/wp-content/uploads/2024/02/awarded.webp" alt="Awarded Best" className="me-3" style={{ width: '40px', height: '40px' }} />
          <div>
            <h5 style={{ color: "#C1B460" }}>AWARDED BEST</h5>
            <p style={{ color: "#15284C" }}>Best web & mobile app development company</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  </div>
  </div>

        <meta charSet="container UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Testimonial Slider</title>
  <link
    href="https://stackpath.bootstrapcdn.com/bootstrap/5.3.0-alpha1/css/bootstrap.min.css"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n    .testimonial-item {\n      background: #fff;\n      padding: 20px;\n      border-radius: 10px;\n      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    }\n    .carousel-caption {\n      position: static;\n      color: #333;\n    }\n    .carousel-indicators [data-bs-target] {\n      background-color: #6c757d;\n    }\n    .carousel-indicators .active {\n      background-color: #343a40;\n    }\n  "
    }}
  />
  <div className="container mt-8 mb-8">
  <h2 className="text-center mb-4 mt-5 fw-bold font-weight-700" style={{ color: "#15284C", fontSize: '40px' }}>Our Testimonial
  <div className="custom-divider"style={{ border: 0,height: '4px',backgroundColor: '#C1B460',width: '9%', margin: '20px auto' }}/>
  </h2>
    <div
      id="testimonialCarousel" 
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide-to={0}
          className="active"
          aria-current="true"
          aria-label="Testimonial 1"
        />
        <button
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide-to={1}
          aria-label="Testimonial 2"
        />
        <button
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide-to={2}
          aria-label="Testimonial 3"
        />
         <button
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide-to={3}
          aria-label="Testimonial 4"
        />
         <button
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide-to={4}
          aria-label="Testimonial 5"
        />
         <button
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide-to={5}
          aria-label="Testimonial 6"
        />
        {/* Add more indicators for additional testimonials */}
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="testimonial-item text-center">
            <p className=" mb-7 mt-7"style={{  fontSize: '18px'}}>  
						Excellent work done by Chirag on the web development, attention to details is really good. I would love to work again and I highly recommend.
            </p>
            <h5 className="fw-bold"style={{ color: "#15284C"}}>Ganesh M</h5>
            <p  className=" mb-6"style={{color:"rgb(193, 180, 96)"}}>( India )</p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="testimonial-item text-center">
          <p className=" mb-7 mt-7" style={{  fontSize: '18px'}}>
          Delivered on time, comprehended project requirements. Great work
            </p>
            <h5 className="fw-bold"style={{ color: "#15284C"}}>Sylvia T</h5>
            <p  className=" mb-6"style={{color:"rgb(193, 180, 96)"}}>( U.K. )</p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="testimonial-item text-center">
          <p className=" mb-7 mt-7" style={{ fontSize: '18px'}}>
              A very competent developer who understand what he does...I will work with him again for sure
            </p>
            <h5 className="fw-bold"style={{ color: "#15284C"}}>Sylvia T</h5>
            <p  className=" mb-6"style={{color:"rgb(193, 180, 96)"}}>( U.K. )</p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="testimonial-item text-center">
          <p className=" mb-7 mt-7" style={{  fontSize: '18px'}}>
          Chirag is perfect in his work.. delivered application in very minimal time and in my budget.. very efficient , very polite and easy to work with him. his designs are great and his work is elegant great work in a short time frame, communicative and easy to work with him. highly recommended.
            </p>
            <h5 className="fw-bold"style={{ color: "#15284C"}}>Sylvia T</h5>
            <p  className=" mb-6"style={{color:"rgb(193, 180, 96)"}}>( U.K. )</p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="testimonial-item text-center">
          <p className=" mb-7 mt-7" style={{ fontSize: '18px'}}>
          Excellent work done by Chirag on the web development, attention to details is really good. I would love to work again and I highly recommend.
            </p>
            <h5 className="fw-bold"style={{ color: "#15284C"}}>Talenna T</h5>
            <p  className=" mb-6"style={{color:"rgb(193, 180, 96)"}}>( U.K. )</p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="testimonial-item text-center">
          <p className=" mb-7 mt-7" style={{  fontSize: '18px'}}>
          He did a pretty good job considering we were busy and had problems with time to communicate. Definitely worth working with.
            </p>
            <h5 className="fw-bold"style={{ color: "#15284C"}}>Jarrod H</h5>
            <p  className="mb-6"style={{color:"rgb(193, 180, 96)"}}>( Australia )</p>
          </div>
        </div>
        {/* Add more testimonials as needed */}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#testimonialCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#testimonialCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  <div style={{ backgroundColor: '#f6f6f6',paddingTop: '20px'   }}>         
  <div className="container my-5">
      <h1 className="text-center mb-5 fw-bold" style={{ color: '#15284C' }}>
        Our Client Says..
        <div
          className="custom-divider"
          style={{
            border: 0,
            height: '4px',
            backgroundColor: 'rgb(193, 180, 96)',
            width: '9%',
            margin: '20px auto'
          }}
        />
      </h1>

      <div className="row justify-content-center">
        <div className="col-md-5 mb-4">
          <video
            src="https://balajitechs.com/wp-content/uploads/2024/07/20240716_165522.mp4"
            className="video-container"
            style={{
              borderRadius: '30px',
              opacity: 0.9,
              width: '100%',
              height: '370px',
              backgroundColor: 'rgb(209 209 209)',
            }}
            controls
          >
            Your browser does not support the video tag.
          </video>
          <h3 style={{ color: "#c5b96b", fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
          Jarrod Harman 
              </h3>
              <p style={{ color: "#15284C", fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
              Owner Business Warriors, Australia 
              </p>
        </div>
        <div className="col-md-5 mb-4">
          <video
            src="https://balajitechs.com/wp-content/uploads/2024/06/VeRAWonica-TESTIMONIAL.mp4"
            className="video-container"
            
            style={{
              borderRadius: '30px',
              opacity: 0.9,
              width: '100%',
              height: '370px',
              backgroundColor: 'rgb(209 209 209)',
            }}
            controls
          >
            Your browser does not support the video tag.
          </video>
          <h3 style={{ color: "#c5b96b", fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
            VeRAWonica 
              </h3>
              <p style={{ color: "#15284C", fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
              LIFEstyle Coach, Canada 
              </p>
        </div>
      </div>

      <style jsx>{`
        .video-container {
          border-radius: 30px;
          opacity: 0.9;
          width: 100%;
          height: auto;
        }
        @media (max-width: 767.98px) {
          .video-container {
            height: 200px; /* Adjust height for mobile view */
          }
        }
      `}</style>
    </div>
    </div>
       <h1 className="text-center mb-5 fw-bold"style={{ color: '#15284C' }}>Frequently Asked Questions
      <div className="custom-divider"style={{ border: 0,height: '4px',backgroundColor: '#C1B460',width: '9%', margin: '20px auto' }}/>
      </h1>

     <div className="accordion mb-7" id="accordionExample"style = {{margin: '10px auto'}} >
      <div className="accordion-item">
       <h2 className="accordion-header" id="headingOne">
         <button
        className="accordion-button fw-bold"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        1. What services does Balaji Technologies offer?
      </button>
    </h2>
    <div
      id="collapseOne"
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
      Balaji Technologies specializes in web development, web design, and CRM management solutions. We offer custom solutions tailored to your specific needs, from building responsive websites to optimizing customer relationships.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button
        className="accordion-button collapsed fw-bold"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo"
      >
        2. Which industries does Balaji Technologies work with?
      </button>
    </h2>
    <div
      id="collapseTwo"
      className="accordion-collapse collapse"
      aria-labelledby="headingTwo"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
      We work with clients across various industries, including e-commerce, healthcare, finance, education, and more. Our diverse expertise allows us to adapt our services to meet the unique requirements of each industry.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button
        className="accordion-button collapsed fw-bold"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseThree"
        aria-expanded="false"
        aria-controls="collapseThree"
      >
        3. How long does it take to complete a project with Balaji Technologies?
      </button>
    </h2>
    <div
      id="collapseThree"
      className="accordion-collapse collapse"
      aria-labelledby="headingThree"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
      The timeline for each project varies depending on its scope and complexity. We work closely with our clients to establish realistic timelines and milestones, ensuring timely delivery without compromising on quality.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingfore">
      <button
        className="accordion-button collapsed fw-bold"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsefore"
        aria-expanded="false"
        aria-controls="collapsefore"
      >
       4. What technologies does Balaji Technologies use for web development?
      </button>
    </h2>
    <div
      id="collapsefore"
      className="accordion-collapse collapse"
      aria-labelledby="headingfore"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
      We leverage a wide range of technologies and platforms for web development, including WordPress, Shopify, ClickFunnels, GoHighLevel, Kartra, Kajabi, Unbounce, GrooveFunnels, Wix, Squarespace, and more. Our team stays up-to-date with the latest trends and tools to deliver cutting-edge solutions.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingfive">
      <button
        className="accordion-button collapsed fw-bold"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsefive"
        aria-expanded="false"
        aria-controls="collapsefive"
      >
       5. How do I request a quote or consultation with Balaji Technologies?
      </button>
    </h2>
    <div
      id="collapsefive"
      className="accordion-collapse collapse"
      aria-labelledby="headingfive"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
      To request a quote or schedule a consultation, simply fill out the contact form on our website or reach out to us via email or phone. Our team will promptly respond to your inquiry and work with you to discuss your project requirements.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingsix">
      <button
        className="accordion-button collapsed fw-bold"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsesix"
        aria-expanded="false"
        aria-controls="collapsesix"
      >
       6. Does Balaji Technologies provide ongoing support and maintenance?
      </button>
    </h2>
    <div
      id="collapsesix"
      className="accordion-collapse collapse"
      aria-labelledby="headingsix"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
      Yes, we offer ongoing support and maintenance services to ensure that your website or CRM system continues to perform optimally. Whether you need technical assistance, updates, or troubleshooting, our team is here to help.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingseven">
      <button
        className="accordion-button collapsed fw-bold"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseseven"
        aria-expanded="false"
        aria-controls="collapseseven"
      >
       7. Is Balaji Technologies available for international projects?
      </button>
    </h2>
    <div
      id="collapseseven"
      className="accordion-collapse collapse "
      aria-labelledby="headingseven"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
      Absolutely! We serve clients worldwide and are accustomed to working with businesses across different time zones and regions. No matter where you’re located, we’re committed to delivering exceptional service and support.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header " id="headingeight">
      <button
        className="accordion-button collapsed fw-bold"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseeight"
        aria-expanded="false"
        aria-controls="collapseeight" 
      >
       8. Can Balaji Technologies assist with digital marketing initiatives?
      </button>
    </h2>
    <div
      id="collapseeight"
      className="accordion-collapse collapse"
      aria-labelledby="headingeight"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
      While our primary focus is on web development, design, and CRM management, we also offer digital marketing services such as SEO, PPC advertising, and social media marketing through our trusted partners. We can connect you with experts who specialize in these areas to help you achieve your marketing goals.</div>
    </div>
  </div>
  
  <style jsx>{`
        #accordionExample {
          width: 80%;
          margin: 10px auto;
        }
           @media (max-width: 768px) {
          #accordionExample {
            width: 90%;
            margin: 5px auto;
          }
        }
          .accordion-button:not(.collapsed) {
          color: var(--bs-accordion-active-color);
          background-color: #cabf73;
          box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
        }
      `}</style>

    </div>

   
    
            </>
        );
    }
}


export default Home;
