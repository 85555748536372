import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();

  const getLinkStyle = (path) => ({
    color: location.pathname === path ? '#C1B460' : '#000',
  });

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img
            src="https://balajitechs.com/wp-content/uploads/2024/02/Logo-1536x493.png"
            alt="Logo"
            width={150}
            height={50}
            style={{ margin: '10px 50px' }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav  mb-1" style={{ gap: '16px',margin: '5px 96px 5px 64px'  }}>
            <li className="nav-item">
              <Link className="nav-link" to="/" style={getLinkStyle('/')}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us" style={getLinkStyle('/about-us')}>
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/portfolio" style={getLinkStyle('/portfolio')}>
                Portfolio
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services" style={getLinkStyle('/services')}>
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/pricing" style={getLinkStyle('/pricing')}>
                Pricing
              </Link>
            </li>
            <li className="nav-item">
                  <Link className="nav-link" to="/get-in-touch" style={getLinkStyle('/get-in-touch')}>
                    Get In Touch
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/call-schedule" style={getLinkStyle('/call-schedule')}>
                    Call Schedule
                  </Link>
                </li>
              
            
          </ul>
          <Link to="/get-proposal" className="btn btn-warning" style={{ backgroundColor: '#C1B460', color: '#fff', border: 'none',padding: '10px 20px 10px 20px' }}>
            Get Proposal
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
