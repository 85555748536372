import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <MDBFooter bgColor='dark' className='text-center text-lg-start text-white'>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
          <div className='me-5 d-none d-lg-block'>
            <span>Get connected with us on social networks:</span>
          </div>
  
          <div>
            <a href='' className='me-4 text-reset'>
              <MDBIcon fab icon="facebook-f" />
            </a>
            <a href='' className='me-4 text-reset'>
              <MDBIcon fab icon="twitter" />
            </a>
            <a href='' className='me-4 text-reset'>
              <MDBIcon fab icon="google" />
            </a>
            <a href='' className='me-4 text-reset'>
              <MDBIcon fab icon="instagram" />
            </a>
            <a href='' className='me-4 text-reset'>
              <MDBIcon fab icon="linkedin" />
            </a>
            <a href='' className='me-4 text-reset'>
              <MDBIcon fab icon="github" />
            </a>
          </div>
        </section>
  
        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>
                  <MDBIcon icon="" className="me-3" />
                  <img
        src="https://balajitechs.com/wp-content/uploads/2024/02/logo-white-2048x657-1-1536x493.png"
        alt="Bootstrap"
        width={150}
        height={50}
        
      />
                  
                </h6>
                <p>
                  Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit amet,
                  consectetur adipisicing elit.
                </p>
              </MDBCol>
  
              <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Quick Links</h6>
                <p>
                  <a href='#!' className='text-reset' >
                  <Link to="/" style={{ color: 'rgb(255 255 255)' }}>Home</Link >
                  
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                  <Link to="/about-us" style={{ color: 'rgb(255 255 255)' }}>About Us</Link>
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                  <Link to="/portfolio" style={{ color: 'rgb(255 255 255)' }}>Portfolio</Link>
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                  <Link to="/services" style={{ color: 'rgb(255 255 255)' }}>Services</Link>
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                  <Link to="/services" style={{ color: 'rgb(255 255 255)' }}>Contact Us</Link>
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                   
                  </a>
                </p>
              </MDBCol>
  
              <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Services</h6>
                <p>
                  <a href='#!' className='text-reset'>
                  Web Development
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                  Web Design
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                  SEO
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                  CRM Management
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                  Graphic Design
                  </a>
                </p>
              </MDBCol>
  
              <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                <p>
                  <MDBIcon icon="home" className="me-2" />
                  Office-403,404, Possible Triangle, Mavdi Main Road, Opp. Sanskar Sanidhya. Rajkot – 360004
                </p>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  info@balajitechs.com
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> +91 8780290170
                </p>
                
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
  
        <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 - Balaji Technologies. All Rights Reserved.
          <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
          	 
          </a>
        </div>
      </MDBFooter>
    );
};

export default Footer;
