import React, { Component } from 'react';

class GetInTouch extends Component { 
    render() { 
        return (
            <>
               <meta charSet="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Pricing Section</title>
  <link
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        .image-section {\n            position: relative;\n            background: url('https://balajitechs.com/wp-content/uploads/2024/03/portrait-smiling-indian-man-headset-making-video-call-with-laptop_116547-69052.png') no-repeat center center;\n            background-size: cover;\n            height: 400px;\n        }\n        .overlay {\n            position: absolute;\n            top: 0;\n            bottom: 0;\n            left: 0;\n            right: 0;\n            background: rgba(0, 0, 0, 0.5);\n        }\n        .text-box {\n            position: absolute;\n            top: 50%;\n            left: 10%;\n            transform: translateY(-50%);\n            color: white;\n        }\n        .highlight {\n            background-color: #d4af37;\n            padding: 0 10px;\n            display: inline-block;\n        }\n    "
    }}
  />
  <div className="image-section">
    <div className="overlay" />
    <div className="text-box">
      <h1 className="highlight fw-bold"style={{backgroundColor:'#c1b460',fontSize: '50px'}}>Get In Touch</h1>
      <h5>Connect with Us: Let's Start a Conversation!</h5>
    </div>
  </div>
                <meta charSet="UTF-8" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<title>Get In Touch</title>
<link
  href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
  rel="stylesheet"
/>
<div className="container mt-5 mb-7" style={{ maxWidth: '100%' }}>
  <h1 className="text-center mb-5 fw-bold" style={{ color: "#15284c",fontSize: '40px' }}>
    Get In Touch
  </h1>
  <p className="mt-3 mb-5"style={{ textAlign: 'center',fontSize: '16px' }}>
            We’d love to hear from you! Whether you have questions about our services, want to discuss a potential project, or simply want to say hello, we’re here to help. Get in touch with us using the contact information below, and one of our team members will get back to you as soon as possible.
          </p>
  <form  className="w-75 mx-auto my-3">
    <div className="form-row">
      <div className="form-group col-md-6">
        
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Name"
        />
      </div>
      <div className="form-group col-md-6">
        
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="Email"
        />
      </div>
    </div>
    <div className="form-group">
      
      <input
        type="tel"
        className="form-control"
        id="phone"
        placeholder="Phone"
      />
    </div>
    <div className="form-group">
      <label className="d-block fw-bold" htmlFor="interest"style={{ color: '#15284C' }}>I AM CURRENTLY INTERESTED IN:</label>
      <div className="form-row">
        <div className="form-group col-md-6">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="interest1" />
            <label className="form-check-label" htmlFor="interest1"style={{ color: '#15284C' }}>
              Web Design & Development
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="interest2" />
            <label className="form-check-label" htmlFor="interest2"style={{ color: '#15284C' }}>
              Shopify Web Design & Development
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="interest3" />
            <label className="form-check-label" htmlFor="interest3"style={{ color: '#15284C' }}>
              Ecommerce Web Design & Development
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="interest4" />
            <label className="form-check-label" htmlFor="interest4"style={{ color: '#15284C' }}>
              Conversion Rate Optimization
            </label>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="interest5" />
            <label className="form-check-label" htmlFor="interest5"style={{ color: '#15284C' }}>
              Search Engine Optimization
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="interest6" />
            <label className="form-check-label" htmlFor="interest6"style={{ color: '#15284C' }}>
              Social Media Ads
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="interest7" />
            <label className="form-check-label" htmlFor="interest7"style={{ color: '#15284C' }}>
              Google Ads 
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="interest8" />
            <label className="form-check-label" htmlFor="interest8"style={{ color: '#15284C' }}>
              Email Marketing
            </label>
          </div>
        </div>
      </div>
    </div>
    <div className="form-group">
      
      <textarea
        className="form-control"
        id="message"
        rows={4}
        placeholder="Please tell us about your project (Goals, Challenges, Deadline, etc, …)"
      />
    </div>
    <button type="submit" className="btn btn-primary"style={{ backgroundColor: '#15284C'}}>
      Send Message
    </button>
  </form>
</div>
<div className="container mt-4 mb-6">
<h1 className="text-center mb-5 fw-bold" style={{ color: "#15284c",fontSize: '50px' }}>
    Contact Now
  </h1>
  <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
    <div className="col">
      <div className="card"style={{ padding: '20px 10px 96px 10px',borderRadius: '20px',textAlign: 'center',boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, 0.5)' }}>
        <img src="https://cdn-icons-png.freepik.com/512/552/552486.png?uid=R138032677&ga=GA1.1.1422998319.1713847519" className="card-img-top" alt="..."style={{ width: '60px', margin: '5px auto' }} />
        <div className="card-body">
          <h5 className="card-title"style={{ color: "#15284c",fontSize: '25px'}}>Email</h5>
          <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" >
               <p className="card-text"style={{ color: "#15284c"}}>info@balajitechs.com</p>
           </a>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card"style={{ padding: '20px 10px 96px 10px',borderRadius: '20px',textAlign: 'center',boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, 0.5)' }}>
        <img src="https://cdn-icons-png.freepik.com/512/724/724664.png?uid=R138032677&ga=GA1.1.1422998319.1713847519" className="card-img-top" alt="..."style={{ width: '60px', margin: '5px auto' }} />
        <div className="card-body">
          <h5 className="card-title"style={{ color: "#15284c",fontSize: '25px'}}>Call Now</h5>
          <a href="https://yourlink.com" target="_blank" rel="noopener noreferrer" >
               <p className="card-text"style={{ color: "#15284c"}}>+91 8780290170</p>
           </a>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card"style={{ padding: '20px 10px 0px 10px',borderRadius: '20px',textAlign: 'center',boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, 0.5)' }}>
        <img src="https://cdn-icons-png.freepik.com/512/12235/12235941.png?uid=R138032677&ga=GA1.1.1422998319.1713847519" className="card-img-top" alt="..."style={{ width: '60px', margin: '5px auto' }} />
        <div className="card-body">
          <h5 className="card-title"style={{ color: "#15284c",fontSize: '25px'}}>Address</h5>
          <p className="card-text"style={{ color: "#15284c"}}>
          OFFICE-403,404, POSSIBLE TRIANGLE, MAVDI MAIN ROAD, OPP. SANSKAR SANIDHYA. RAJKOT - 360004
          </p>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card"style={{ padding: '20px 20px 72px 20px',borderRadius: '20px',textAlign: 'center',boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, 0.5)' }}>
        <img src="https://cdn-icons-png.freepik.com/512/11262/11262793.png?uid=R138032677&ga=GA1.1.1422998319.1713847519" className="card-img-top" alt="..."style={{ width: '60px', margin: '5px auto' }} />
        <div className="card-body">
          <h5 className="card-title"style={{ color: "#15284c",fontSize: '25px'}}>Visit Hours</h5>
          <p className="card-text"style={{ color: "#15284c"}}>
            Mon to Sat: 10AM to 7PM
            Sun: Closed
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="container mt-4 mb-6">
    <div className="row align-items-center">
      <div className="col-md-6">
        <h5>
          Stay updated on the latest news, trends, and insights from Balaji Technologies by following us on social media. Connect with us on
        </h5>
        <p>
          Don’t hesitate to reach out to us. We’re here to assist you every step of the way. Let’s start the conversation and explore how we can help you achieve your digital goals.
          LinkedIn
        </p>
        <img src="https://img.freepik.com/free-psd/social-media-logo-design_23-2151296991.jpg?t=st=1721645766~exp=1721649366~hmac=10e09f4d08d007ad149294851ab72ba615529bb9c33d338b55cc86f0457c6134&w=360" alt="Social Media Logo"style={{width:'100px'}}></img>
      </div>
      <div className="col-md-6">
        <img
          src="https://balajitechs.com/wp-content/uploads/2024/04/finger-pressing-social-network-button-touch-screen-2048x1472.jpg"
          alt="Social Media"
          className="img-fluid rounded-custom"style={{ borderRadius: '20px'}}
        />
        <button type="button" class="btn btn-primary"style={{margin: '23px auto' ,backgroundColor: '#c1b460',borderColor: '#c1b460',padding: '6px 30px 6px 30px'}} data-toggle="button" aria-pressed="false" autocomplete="off">
        Schedule a Call
         </button>
             </div>
             </div>
             </div>

          </>
        );
    }
}

export default GetInTouch;