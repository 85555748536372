import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


const LandingPage = () => {
  return (
    <>
     <meta charSet="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>About Us Section</title>
  <link
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        .image-section {\n            position: relative;\n            background: url('https://balajitechs.com/wp-content/uploads/2024/06/accountant-calculating-profit-with-financial-analysis-graphs-scaled.jpg') no-repeat center center;\n            background-size: cover;\n            height: 400px;\n        }\n        .overlay {\n            position: absolute;\n            top: 0;\n            bottom: 0;\n            left: 0;\n            right: 0;\n            background: rgba(0, 0, 0, 0.5);\n        }\n        .text-box {\n            position: absolute;\n            top: 50%;\n            left: 10%;\n            transform: translateY(-50%);\n            color: white;\n        }\n        .highlight {\n            background-color: #d4af37;\n            padding: 0 10px;\n            display: inline-block;\n        }\n    "
    }}
  />
  <div className="image-section">
    <div className="overlay" />
    <div className="text-box">
      <h1 className="highlight fw-bold"style={{backgroundColor:'#c1b460',fontSize: '50px'}}>Pricing</h1>
      <h5>Find the Right Plan: Pricing Options that Fit Your Budget</h5>
    </div>
  </div>
    <div className="container my-5">
      <h1 className="text-center mb-5 fw-bold"style={{ color: '#15284C',fontSize: '50px' }}>Pricing Plans</h1>
      <div className="row">
        {/* Service 1 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>Landing Page</h5>
              <p className="card-text">Designed for small businesses or special promos, our Landing Page Service offers a professional, impactful online presence with just two essential pages: a striking Home page and a concise Contact Us page.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $2,500</h6>
              <p className="card-text">(One time fee, 3 installments)</p>
            </div>
          </div>
        </div>
        {/* Service 2 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>Web Design and Development</h5>
              <p className="card-text">Crafting bespoke, user-friendly websites that encapsulate your brand’s essence and engage your audience, ensuring a seamless digital experience (Includes up to 10 pages).</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $5,500</h6>
              <p className="card-text">(One time fee, 3 installments)</p>
            </div>
          </div>
        </div>
        {/* Service 3 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>eCommerce Website Design</h5>
              <p className="card-text">Designing visually stunning, highly functional eCommerce platforms that provide an intuitive shopping experience, driving sales and enhancing customer satisfaction.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $7,500</h6>
              <p className="card-text">(One time fee, 3 installments)</p>
            </div>
          </div>
        </div>
        {/* Service 4 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>TikTok Ads Management</h5>
              <p className="card-text">Leveraging the unique dynamics of TikTok to design creative, impactful ad campaigns that resonate with the platform’s diverse and engaged audience.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $1,250 or 15% of ad spend, whichever is greater</h6>
              
            </div>
          </div>
        </div>
        {/* Service 5 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>Meta Ads Management (FB & IG)</h5>
              <p className="card-text">Expertly navigating the Meta ecosystem to create and manage compelling ad campaigns on Facebook and Instagram, tailored to engage your audience and drive meaningful results.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $1,250 or 15% of ad spend, whichever is greater</h6>
              
            </div>
          </div>
        </div>
        {/* Service 6 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>Google Ads Management</h5>
              <p className="card-text">Strategically managing your Google Ads campaigns to maximize visibility, drive targeted traffic, and achieve a higher return on investment through precise targeting and optimization.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $1,250 or 15% of ad spend, whichever is greater</h6>
              
            </div>
          </div>
        </div>
        {/* Service 7 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>Search Engine Optimization</h5>
              <p className="card-text">Boost your search rankings with our expert SEO. We enhance your site to attract more organic traffic, tailored to your industry for maximum visibility.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $2,000 per month</h6>
              
            </div>
          </div>
        </div>
        {/* Service 8 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>Conversion Rate Optimization</h5>
              <p className="card-text">Utilizing data-driven insights to refine and optimize your website’s user journey, boosting conversion rates and maximizing the effectiveness of your online presence.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $2,000 per month</h6>
              
            </div>
          </div>
        </div>
        {/* Service 9 */}  
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>Email Marketing & SMS</h5>
              <p className="card-text">Deploying targeted, personalized email and SMS campaigns that nurture leads, foster customer loyalty, and drive conversions through carefully crafted messages.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $1,500</h6>
            </div>
          </div>
        </div>
        {/* Service 10 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>Content Creation</h5>
              <p className="card-text">Producing high-quality, captivating visual content through professional photography and videography that tells your brand’s story and engages your target audience.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $2,500</h6>
              
            </div>
          </div>
        </div>
        {/* Service 1 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold"style={{ color: '#15284C',fontSize: '28px'}}>Web Dev Support</h5>
              <p className="card-text">Get swift, expert support for your website with our service. We handle updates, optimizations, and fixes to keep your site running smoothly.</p>
              <h6 className="card-price fw-bold"style={{ color: '#15284C',fontSize: '18px'}}>Starting at $499 per month</h6>
              
            </div>
          </div>
          <button type="button" class="btn btn-primary"style={{ margin: '12px 0px 0px 112px',backgroundColor: '#c1b460',borderColor: '#c1b460',padding: '10px 30px 10px 30px'  }} data-toggle="button" aria-pressed="false" autocomplete="off">
          Get Proposal
         </button> 
        </div>
      </div>
    </div>
    </>
  );
}

export default LandingPage;
